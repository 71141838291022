var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about"},[_c('h1',[_vm._v("Help")]),_c('p',[_vm._v("A new Jumble is available daily.")]),_c('p',[_vm._v("Find at least one nine-letter word.")]),_c('p',[_vm._v("Using the nine letters in the grid, create words of four letters or more.")]),_c('p',[_vm._v("The centre letter must be included and each letter may be used only once.")]),_c('p',[_vm._v("No colloquial or foreign words.")]),_c('p',[_vm._v("No capitalised nouns, apostrophes or plural words ending in 's'.")]),_c('p',[_vm._v("Reference Source: Macquarie Dictionary")]),_c('p',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"butShuffle","fab":"","dark":"","small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-sync-alt")])],1)]}}])},[_c('span',[_vm._v("Shuffle Letters")])]),_vm._v(" Shuffle letters ")],1),_c('p',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"butBackspace","fab":"","dark":"","small":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-arrow-alt-left")])],1)]}}])},[_c('span',[_vm._v("Backspace")])]),_vm._v(" Remove one letter from end of guess (Backspace) ")],1),_c('p',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"butClear","fab":"","dark":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-ban")])],1)]}}])},[_c('span',[_vm._v("Clear Input")])]),_vm._v(" Clear input box ")],1),_c('p',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"butCheck","fab":"","dark":"","small":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-check")])],1)]}}])},[_c('span',[_vm._v("Check Guess")])]),_vm._v(" Check answer ")],1),_c('h5',[_vm._v("Technical Support:")]),_c('p',[_vm._v("To all the iOS users who were impacted by the third party cookie issue over the last month. We are sorry you were unable to play during this time, unfortunately all reported issues came through as anonymous and therefore we were unable to respond. Please know that we were working hard on a fix for this issue and appreciate you taking the time to report bugs. We hope you enjoy the new game and please raise any concerns via the links below.")]),_vm._m(0),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{attrs:{"href":"https://generaltechnical.com.au","target":"_blank"}},[_vm._v("General Technical Website")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{attrs:{"href":"mailto:support@generaltechnical.com.au?subject=Auspac Media Alfakodo Issue"}},[_vm._v(" Report an issue now ")])])}]

export { render, staticRenderFns }